import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useLocation } from 'react-router-dom';
import { useGetUserQuery } from 'src/common/slices';
import { anonymousId } from 'src/common/tracking/data';
import { useFeatureIsOn } from '../growthbook';
const writeKey = process.env.REACT_APP_SEGMENT_ANALYTICS_WRITE_KEY ?? '';
export const analytics = writeKey ? AnalyticsBrowser.load({
  writeKey
}) : null;
export const useSegmentAnalytics = () => {
  const isLoggedOutTrackingEnabled = useFeatureIsOn('is-logged-out-tracking-enabled');
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    data: userData
  } = useGetUserQuery();
  const userId = userData?.data?.user?._id;
  const location = useLocation();
  const [referrer, setReferrer] = useState(document.referrer);
  useEffect(() => {
    const initializeAnalytics = async () => {
      if (!isLoggedOutTrackingEnabled || !analytics || !anonymousId) return;
      await analytics.setAnonymousId(anonymousId);
      setIsInitialized(true);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeAnalytics();
  }, [isLoggedOutTrackingEnabled]);
  useEffect(() => {
    if (!analytics || !isLoggedOutTrackingEnabled || !isInitialized) return;
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analytics.page({
      referrer,
      url: fullUrl,
      path: location.pathname,
      title: document.title,
      search: location.search
    });
    setReferrer(fullUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedOutTrackingEnabled, location.pathname, isInitialized]);
  useEffect(() => {
    if (!analytics || !isLoggedOutTrackingEnabled || !isInitialized) return;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (userId) analytics.identify(userId);
  }, [isLoggedOutTrackingEnabled, userId, isInitialized]);
};